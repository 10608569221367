import BasePublicApiClient from '@acme/api-client-with-identity/dist/BasePublicApiClient';
import { PrivateApiClientProvider } from '@acme/wallet-utils';

import { config } from '../config';
import { accessStorage, refreshStorage } from '../managers';

const identityBaseApiClient = new BasePublicApiClient({
  credentialsStorage: {
    accessToken: accessStorage,
    refreshToken: refreshStorage,
  },
  privateApiClientProvider: new PrivateApiClientProvider({
    privateApiUrl: config.privateApiUrl,
  }),
  publicApiUrl: config.publicApiUrl,
});

export default identityBaseApiClient;
