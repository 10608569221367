interface IWindow extends Window {
  _env_: Record<string, string>;
}

function getVariable(name: string): string {
  if (process.env.NODE_ENV === 'development') {
    return process.env[name] || '';
  }
  // eslint-disable-next-line no-underscore-dangle
  return ((window as unknown) as IWindow)._env_[name];
}

export const config = {
  apiUrl: getVariable('REACT_APP_API_URL'),
  privateApiUrl: getVariable('REACT_APP_PRIVATE_API_URL'),
  publicApiUrl: getVariable('REACT_APP_PUBLIC_API_URL'),
};
