import { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core';
import {
  DefaultParams,
  Redirect,
  Route,
  RouteProps,
  Switch,
  useLocation,
} from 'wouter';

import identityBaseApiClient from './api/identityBaseApiClient';
import theme from './utils/theme';
import { Layout } from './components/Layout';
import { Login } from './components/Login';
import { Cards } from './components/Cards';
import { Transactions } from './components/Transactions';
import { CreatePromocode, Promocodes } from './components/Promocodes';

const PrivateRoute = <T extends DefaultParams = DefaultParams>(
  routeProps: RouteProps<T>,
) => {
  const [location] = useLocation();
  const getEmail = localStorage.getItem('email');

  useEffect(() => {
    identityBaseApiClient.initFromCredentialsStorage();
  }, [getEmail]);

  if (localStorage.getItem('access_token')) {
    if (location === '/login' || location === '/') {
      return <Redirect to="/cards" />;
    }

    return <Route {...routeProps} />;
  }

  if (location === '/login') {
    return <Route {...routeProps} />;
  }

  return <Redirect to="/login" />;
};

export const App = () => (
  <ThemeProvider theme={theme}>
    <Layout>
      <Switch>
        <PrivateRoute path="/" />
        <PrivateRoute path="/cards" component={Cards} />
        <PrivateRoute path="/cards/:tab" component={Cards} />
        <PrivateRoute path="/transactions" component={Transactions} />
        <PrivateRoute path="/promocodes" component={Promocodes} />
        <PrivateRoute path="/promocodes/create" component={CreatePromocode} />
        <PrivateRoute path="/login" component={Login} />
      </Switch>
    </Layout>
  </ThemeProvider>
);
