import PublicApiClient from '@acme/ownr-public-http-api-client';

import { config } from '../config';

const publicApiClient = new PublicApiClient(config.publicApiUrl);

export async function refreshAccess() {
  const refreshToken = localStorage.getItem('refresh_token');
  if (refreshToken) {
    const response = await publicApiClient.refreshAccess({
      refresh_token: refreshToken,
    });
    if (response.http_status_code === 200) {
      localStorage.setItem('access_token', response.access_token);
      return true;
    }
    if (
      response.error_code === 'UNAUTHORIZED' ||
      response.error_code === 'EXPIRED'
    ) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      window.location.href = '/login';
    }
  }
  return false;
}
