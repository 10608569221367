import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  CardPartnerLedgerAction,
  CardPartnerLedgerRecord,
  CardPartnerLedgerRecordType,
  CardPartnersTransferFundsStatus,
} from '@acme/partners-private-http-api-client/dist/types';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { useInterval, useLocation, useUpdateEffect } from 'react-use';
import {
  Button,
  colors,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import { endOfDay, parseISO, startOfDay, subMonths } from 'date-fns';

import { IColumn, InfiniteTable } from '../InfiniteTable';
import { getLedgerRecords } from '../../api';
import theme from '../../utils/theme';
import { usePartners } from '../../hooks/usePartners';
import { snakeCaseToSentence } from '../../libs/helpers';

const columns: Array<IColumn<CardPartnerLedgerRecord>> = [
  {
    field: 'cp_order_status',
    label: 'Status',
    format: (row) => snakeCaseToSentence(row.cp_order_status),
  },
  {
    field: 'created_at',
    label: 'Date and Time',
    format: (row) => (
      <>
        {new Date(row.created_at).toLocaleString()}
        <br />
        {formatDistanceToNow(new Date(row.created_at), { addSuffix: true })}
      </>
    ),
    minWidth: 180,
  },
  {
    label: 'Amount',
    field: 'amount',
    format: (row) => (
      <div
        style={{
          color: row.record_type === 'debit' ? theme.palette.success.dark : '',
          fontWeight: 500,
        }}
      >
        {row.record_type === 'credit' && '-'}
        {Number(row.amount).toFixed(2)} {row.currency}
      </div>
    ),
    align: 'right',
    minWidth: 120,
  },
  {
    field: 'memo',
    label: 'Memo',
  },
];

const useStyles = makeStyles((t) => ({
  filter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: t.spacing(2),
    padding: t.spacing(2),
  },
  dates: {
    marginLeft: t.spacing(1),
  },
  date: {
    marginLeft: t.spacing(1),
    width: 180,
  },
  selectWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    minWidth: 180,
    marginRight: t.spacing(1),
  },
}));

const LIMIT = 20;

const rowColors: {
  [K in CardPartnersTransferFundsStatus]?: string;
} = {
  NEW: colors.yellow.A400,
  IN_PROGRESS: colors.lightBlue.A200,
  COMPLETED: colors.green.A400,
  FAILED: colors.red.A200,
};

export function getRowColor(status: CardPartnersTransferFundsStatus) {
  return rowColors[status] || colors.lightBlue.A200;
}

const now = new Date();
const [dateFromDefault] = subMonths(now, 1).toISOString().split('T');
const [dateToDefault] = now.toISOString().split('T');

const recordActions: Array<CardPartnerLedgerAction> = [
  'create_promocode',
  'card_refill',
];
const recordTypes: Array<CardPartnerLedgerRecordType> = ['debit', 'credit'];

export const Transactions = () => {
  const classes = useStyles();
  const [ledger, setLedger] = useState<Array<CardPartnerLedgerRecord>>([]);
  const selectedPartnerId = usePartners((s) => s.selectedPartnerId);
  const [partnerId, setPartnerId] = useState(selectedPartnerId);
  const [errorMessage, setErrorMessage] = useState<string>();

  const [filter, setFilter] = useState<{
    action?: CardPartnerLedgerAction;
    type?: CardPartnerLedgerRecordType;
  }>({
    action: undefined,
    type: undefined,
  });

  const [dateFrom, setDateFrom] = useState(dateFromDefault);
  const [dateTo, setDateTo] = useState(dateToDefault);

  const location = useLocation();
  const cardId = useMemo(
    () => new URLSearchParams(location.search).get('card_id'),
    [location],
  );

  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const skipRef = useRef(0);

  useUpdateEffect(() => {
    if (selectedPartnerId) {
      setPartnerId(selectedPartnerId);
    }
  }, [selectedPartnerId]);

  const fetchLedger = useCallback(
    async (reset = false) => {
      if (partnerId) {
        setLoading(true);

        if (reset) {
          skipRef.current = 0;
          setHasMore(true);
        }
        const response = await getLedgerRecords({
          card_partner_id: partnerId,
          skip: skipRef.current,
          limit: LIMIT,
          card_id: cardId,
          from_date: startOfDay(parseISO(dateFrom)).toISOString(),
          to_date: endOfDay(parseISO(dateTo)).toISOString(),
          record_action: filter.action,
          record_type: filter.type,
        });
        if (response.http_status_code === 200) {
          setLedger((s) =>
            reset ? response.items : [...s, ...response.items],
          );

          if (response.items.length < LIMIT) {
            setHasMore(false);
          }
        } else if (response.error_message) {
          setErrorMessage(response.error_message);
        }
        setLoading(false);
      }
    },
    [partnerId, cardId, dateFrom, dateTo, filter],
  );

  const fetchActiveTransactions = useCallback(async () => {
    if (partnerId) {
      const response = await getLedgerRecords({
        card_partner_id: partnerId,
        limit: ledger.length,
        card_id: cardId,
        skip: 0,
        from_date: startOfDay(parseISO(dateFrom)).toISOString(),
        to_date: endOfDay(parseISO(dateTo)).toISOString(),
        record_action: filter.action,
        record_type: filter.type,
      });
      if (response.http_status_code === 200) {
        setLedger((s) => {
          const newTransactions: Array<CardPartnerLedgerRecord> = [];
          const state = [...s];
          let isChanged = false;
          response.items.forEach((transaction) => {
            const index = state.findIndex(
              (x) => x.record_id === transaction.record_id,
            );
            if (index === -1) {
              newTransactions.push(transaction);
            } else if (!isEqual(state[index], transaction)) {
              isChanged = true;
              state[index] = transaction;
            }
          });
          if (newTransactions.length > 0 || isChanged) {
            return [...newTransactions, ...state];
          }
          return s;
        });
      }
    }
  }, [partnerId, ledger.length, cardId, dateFrom, dateTo, filter]);

  const onSkip = useCallback(() => {
    skipRef.current += LIMIT;
    void fetchLedger();
  }, [fetchLedger]);

  useEffect(() => {
    void fetchLedger(true);
  }, [fetchLedger]);

  const intervalTimeout = useMemo(() => (loading ? null : 5 * 1000), [loading]);
  useInterval(fetchActiveTransactions, intervalTimeout);

  const onFilterChange = useCallback(
    (evt: React.ChangeEvent<{ value: unknown }>) => {
      const value = evt.target.value as
        | CardPartnerLedgerAction
        | CardPartnerLedgerRecordType;

      if (recordActions.includes(value as CardPartnerLedgerAction)) {
        setFilter({
          action: value as CardPartnerLedgerAction,
          type: undefined,
        });
      }
      if (recordTypes.includes(value as CardPartnerLedgerRecordType)) {
        setFilter({
          action: undefined,
          type: value as CardPartnerLedgerRecordType,
        });
      }
    },
    [],
  );

  const onResetClick = useCallback(() => {
    setFilter({ action: undefined, type: undefined });
  }, []);
  return (
    <>
      <Paper className={classes.filter}>
        <div className={classes.selectWrapper}>
          <FormControl
            size="small"
            variant="outlined"
            className={classes.select}
          >
            <InputLabel htmlFor="filter-select">Filter</InputLabel>
            <Select
              inputProps={{
                id: 'filter-select',
              }}
              label="Filter"
              onChange={onFilterChange}
            >
              {[...recordActions, ...recordTypes].map((r) => (
                <MenuItem key={r} value={r}>
                  {snakeCaseToSentence(r)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button onClick={onResetClick} type="button" color="primary">
            Reset
          </Button>
        </div>
        <div className={classes.dates}>
          <TextField
            label="From"
            variant="outlined"
            size="small"
            type="date"
            value={dateFrom}
            onChange={(evt) => {
              setDateFrom(evt.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.date}
          />
          <TextField
            label="To"
            variant="outlined"
            size="small"
            type="date"
            value={dateTo}
            onChange={(evt) => {
              setDateTo(evt.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.date}
          />
        </div>
      </Paper>
      <InfiniteTable
        data={ledger}
        columns={columns}
        rowKey="record_id"
        loading={loading}
        onSkip={onSkip}
        hasMore={hasMore}
        errorMessage={errorMessage}
        rowStyle={(row) => ({
          borderLeft: `10px solid ${getRowColor(row.cp_order_status)}`,
        })}
      />
    </>
  );
};
