import PublicHttpApiExternalHttpApiClient from '@acme/ownr-public-http-api-client';

import { config } from '../config';

const publicApi = new PublicHttpApiExternalHttpApiClient(config.publicApiUrl);

export async function identityLogin({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  const initAuth = await publicApi.initAuthentication({
    auth_schema: 'MFA_PASSWORD_EMAIL',
  });

  if (initAuth.status !== 'OK') {
    throw new Error(
      `identity_authentication_error_response:
          ${initAuth.error_code}:
          ${initAuth.error_message}`,
    );
  }

  const initAffirmationPassword = await publicApi.initAffirmation({
    auth_token: initAuth.auth_token,
    subject: 'PASSWORD',
    value: email,
  });

  if (initAffirmationPassword.status !== 'OK') {
    if (initAffirmationPassword.error_code === 'UNAUTHORIZED') {
      initAffirmationPassword.error_message = 'email_not_found';
    }

    throw new Error(
      `identity_affirmation_email_error_response:
          ${initAffirmationPassword.error_code}:
          ${initAffirmationPassword.error_message}`,
    );
  }

  const completeAffirmationPassword = await publicApi.completeAffirmation({
    auth_token: initAffirmationPassword.auth_token,
    subject: 'PASSWORD',
    secret: password,
  });

  if (completeAffirmationPassword.status !== 'OK') {
    if (completeAffirmationPassword.error_code === 'INCOMPLETE') {
      completeAffirmationPassword.error_message = 'wrong_password';
    }
    throw new Error(
      `identity_affirmation_password_error_response:
          ${completeAffirmationPassword.error_code}:
          ${completeAffirmationPassword.error_message}`,
    );
  }

  const initSecretAffirmation = await publicApi.initAffirmation({
    auth_token: completeAffirmationPassword.auth_token,
    subject: 'EMAIL',
    value: email,
  });

  if (initSecretAffirmation.status !== 'OK') {
    throw new Error(
      `identity_init_secret_affirmation_error_response:
          ${initSecretAffirmation.error_code}:
          ${initSecretAffirmation.error_message}`,
    );
  }
  return {
    authToken: initSecretAffirmation.auth_token,
  };
}

export async function identitySecretConfirm({
  authToken,
  secret,
}: {
  authToken: string;
  secret: string;
}) {
  const completeSecretAffirmation = await publicApi.completeAffirmation({
    auth_token: authToken,
    subject: 'EMAIL',
    secret,
  });

  if (completeSecretAffirmation.status !== 'OK') {
    throw new Error(
      `identity_affirmation_password_error_response:
          ${completeSecretAffirmation.error_code}:
          ${completeSecretAffirmation.error_message}`,
    );
  }

  const completeAuth = await publicApi.completeAuthentication({
    auth_token: completeSecretAffirmation.auth_token,
    allow_refresh: true,
  });

  if (completeAuth.status !== 'OK' || !completeAuth.refresh_token) {
    throw new Error('identity_complete_auth_error_response');
  }

  const accessToken = completeAuth.access_token;
  const refreshToken = completeAuth.refresh_token;

  return {
    accessToken,
    refreshToken,
  };
}
