export function convertPromo(promo?: string) {
  if (!promo) {
    return 'XXXX XXXX XXXX XXXX';
  }

  const spliced = [...promo].splice(3, 4, '*').join('');
  return promo.replace(spliced, '****');
}

export function capitalizeEveryWord(sentence: string) {
  return sentence
    .split('_')
    .map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`)
    .join(' ');
}

export function snakeCaseToSentence(text: string) {
  const [firstWord, ...rest] = text.split('_');
  return [
    `${firstWord.charAt(0).toUpperCase()}${firstWord
      .slice(1)
      .toLocaleLowerCase()}`,
    ...rest.map((w) => w.toLocaleLowerCase()),
  ].join(' ');
}
