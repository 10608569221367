import { useEffect, useState } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { RouteComponentProps, useLocation } from 'wouter';

import { TabsEnum } from './tabs.enum';
import { CardsList } from './CardsList';

export const Cards = ({ params }: RouteComponentProps<{ tab: TabsEnum }>) => {
  const [tab, setTab] = useState(params.tab || TabsEnum.Partner);
  const [, setLocation] = useLocation();

  useEffect(() => {
    setLocation(`/cards/${tab || TabsEnum.Partner}`);
  }, [tab, setLocation]);

  return (
    <>
      <Tabs
        value={tab}
        onChange={(_, value: TabsEnum) => setTab(value)}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab value={TabsEnum.Partner} label="Cards" />
        <Tab value={TabsEnum.Pending} label="Pending" />
      </Tabs>
      <CardsList tab={tab} />
    </>
  );
};
