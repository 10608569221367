import { memo, useState } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { convertPromo } from '../../libs/helpers';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
  },
}));

export const Promo = memo(({ promo }: { promo?: string | null }) => {
  const classes = useStyles();
  const [showPromo, setShowPromo] = useState(false);

  if (!promo) {
    return <span>—</span>;
  }

  return (
    <span>
      {showPromo ? promo : convertPromo(promo)}
      <IconButton
        className={classes.button}
        component="span"
        onClick={() => setShowPromo((s) => !s)}
      >
        {showPromo ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </IconButton>
    </span>
  );
});
