import React, {
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Snackbar,
  SnackbarContent,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  InputLabel,
  Select,
  FormControl,
  Divider,
  ButtonBase,
  Avatar,
  Menu,
  MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReorderIcon from '@material-ui/icons/Reorder';
import { Link, useLocation } from 'wouter';
import cx from 'clsx';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { CardPartnerView } from '@acme/partners-private-http-api-client/dist/types';
import { useInterval } from 'react-use';

import Logo from '../../assets/logo.png';
import { useSnackbar, SnackbarType } from '../../hooks/useSnackbar';
import { usePartners } from '../../hooks/usePartners';
import { clearAuthorizationToken, getPartner } from '../../api';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100% - ${drawerWidth}px)`,
  },
  logoContainer: {
    marginRight: theme.spacing(2),
    width: 48,
    height: 48,
    cursor: 'pointer',
  },
  logoImg: {
    width: '100%',
    height: '100%',
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
  },
  avatar: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  purpleAvatar: {
    margin: theme.spacing(1),
    color: '#fff',
    backgroundColor: theme.palette.info.main,
  },
  meta: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  snackbarError: {
    backgroundColor: theme.palette.error.main,
  },
  snackbarSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  title: {
    flexGrow: 1,
  },
  signOut: {
    color: '#fff',
  },
  partnersSelect: {
    margin: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
}));

export const Layout = memo(({ children }: PropsWithChildren<unknown>) => {
  const classes = useStyles();
  const [location, setLocation] = useLocation();
  const [partner, setPartner] = useState<CardPartnerView>();

  const getEmail = useCallback(() => localStorage.getItem('email'), []);

  const { snackbarValue, setSnackbarValue, snackbarType } = useSnackbar();
  const {
    partners,
    selectedPartnerId,
    setSelectedPartnerId,
    fetchPartners,
  } = usePartners();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const partnerId = useMemo(() => selectedPartnerId || 'none', [
    selectedPartnerId,
  ]);

  const fetchPartner = useCallback(async () => {
    if (selectedPartnerId) {
      const response = await getPartner(selectedPartnerId);
      if (response.http_status_code === 200) {
        setPartner(response.partner);
      }
    }
  }, [selectedPartnerId]);

  const onSnackbarClose = useCallback(() => {
    setSnackbarValue('');
  }, [setSnackbarValue]);

  const signOut = useCallback(() => {
    handleClose();
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('card_partner_id');
    localStorage.removeItem('email');
    clearAuthorizationToken();
    setLocation('/login');
  }, [setLocation, handleClose]);

  const onChangePartner = useCallback(
    (evt: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      setSelectedPartnerId(evt.target.value as string);
    },
    [setSelectedPartnerId],
  );

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      void fetchPartners();
    }
  }, [fetchPartners]);

  useEffect(() => {
    void fetchPartner();
  }, [fetchPartner]);

  useInterval(fetchPartner, getEmail() ? 3000 : null);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Link href="/wallet">
            <div className={classes.logoContainer}>
              <img className={classes.logoImg} src={Logo} alt="logo" />
            </div>
          </Link>
          <Typography variant="h6" className={classes.title}>
            Partners
          </Typography>
          {localStorage.getItem('access_token') && (
            <div>
              <ButtonBase
                type="button"
                onClick={handleMenu}
                className={classes.avatar}
              >
                <Avatar className={classes.purpleAvatar}>
                  {getEmail()?.charAt(0).toUpperCase()}
                </Avatar>
                <Typography>{getEmail()}</Typography>
              </ButtonBase>
              <Menu
                open={Boolean(anchorEl)}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClose={handleClose}
              >
                <Button
                  style={{
                    fontSize: '16px',
                  }}
                  onClick={signOut}
                >
                  <ExitToAppIcon style={{ marginRight: '6px' }} />
                  Sign Out
                </Button>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {localStorage.getItem('access_token') && (
        <Drawer
          variant="permanent"
          anchor="left"
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          {partners.length > 0 && (
            <FormControl
              size="small"
              variant="outlined"
              className={classes.partnersSelect}
            >
              <InputLabel htmlFor="partner-select">Partner</InputLabel>
              <Select
                inputProps={{
                  id: 'partner-select',
                }}
                label="Partner"
                onChange={onChangePartner}
                value={partnerId}
              >
                {partners.map((p) => (
                  <MenuItem key={p.card_partner_id} value={p.card_partner_id}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Divider className={classes.divider} />
          <div className={classes.meta}>
            <Typography gutterBottom variant="h5">
              {partner?.name}
            </Typography>
            <Typography variant="body1">
              Balance:{' '}
              <strong>
                {partner
                  ? `${Number(partner.balance_amount).toFixed(2)} ${
                      partner.balance_currency
                    }`
                  : '—'}
              </strong>
            </Typography>
          </div>
          <Divider />
          <List>
            <ListItem
              button
              selected={location.includes('/cards')}
              onClick={() => setLocation('/cards')}
            >
              <ListItemIcon>
                <CreditCardIcon />
              </ListItemIcon>
              <ListItemText primary="Cards" />
            </ListItem>
            <ListItem
              button
              selected={location.includes('/transactions')}
              onClick={() => setLocation('/transactions')}
            >
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Transactions" />
            </ListItem>
            <ListItem
              button
              selected={location.includes('/promocodes')}
              onClick={() => setLocation('/promocodes')}
            >
              <ListItemIcon>
                <ReorderIcon />
              </ListItemIcon>
              <ListItemText primary="Promocodes" />
            </ListItem>
          </List>
        </Drawer>
      )}
      <main className={classes.content}>
        <Toolbar />
        {children}
      </main>
      <Snackbar
        open={Boolean(snackbarValue)}
        onClose={onSnackbarClose}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <SnackbarContent
          className={cx({
            [classes.snackbarError]: snackbarType === SnackbarType.Error,
            [classes.snackbarSuccess]: snackbarType === SnackbarType.Success,
          })}
          message={snackbarValue}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={onSnackbarClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    </div>
  );
});
