import { memo, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { CardPartnerUserData } from '@acme/partners-private-http-api-client/dist/types';
import { useFormik } from 'formik';
import { Link } from 'wouter';

import { Promo } from '../Promo';
import { calculateCardRefillAmount, refillUserCard } from '../../api';
import { LoadingButton } from '../LoadingButton';
import { usePartners } from '../../hooks/usePartners';
import { SnackbarType, useSnackbar } from '../../hooks/useSnackbar';

import { TabsEnum } from './tabs.enum';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(3),
  },
  refillDescription: {
    marginTop: theme.spacing(1),
  },
  cardTopUp: {
    marginTop: theme.spacing(2),
  },
}));

export const CardContent = memo(
  ({ row, tab }: { row: CardPartnerUserData; tab: TabsEnum }) => {
    const classes = useStyles();
    const [transfer, setTransfer] = useState(false);
    const partnerId = usePartners((s) => s.selectedPartnerId);
    const [refillAmountData, setRefillAmountData] = useState<{
      refill_amount: string;
      total_amount: string;
      fee_percent: string;
    }>();

    const setSnackbarValue = useSnackbar((s) => s.setSnackbarValue);

    const formik = useFormik({
      initialValues: {
        amount: '',
      },
      async onSubmit(values, actions) {
        const response = await refillUserCard({
          card_id: row.card_id || '',
          card_partner_id: partnerId || '',
          target_user_identity_id: row.identity_id,
          amount: values.amount,
        });
        if (response.http_status_code === 200) {
          setSnackbarValue('Top up is succeed', SnackbarType.Success);
          setTransfer(false);
        } else {
          actions.setFieldError(
            'amount',
            response.error_message || response.error_code,
          );
        }
      },
    });

    const fetchRefillAmount = useCallback(async () => {
      if (partnerId) {
        const amountNumber = Number(formik.values.amount.trim());
        if (!Number.isNaN(amountNumber)) {
          if (amountNumber > 0) {
            const response = await calculateCardRefillAmount(
              partnerId,
              amountNumber.toString(),
            );
            if (response.http_status_code === 200) {
              setRefillAmountData({
                refill_amount: response.card_refill_amount,
                total_amount: response.total_payment_amount,
                fee_percent: response.card_refill_fee_percent,
              });
            }
          }
        }
      }
    }, [partnerId, formik.values.amount]);

    useEffect(() => {
      void fetchRefillAmount();
    }, [fetchRefillAmount]);

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="body1">Card:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>
                {row.card_number_masked ? (
                  <Link href={`/transactions?card_id=${row.card_id}`}>
                    {row.card_number_masked} {row.card_expiration_month}/
                    {row.card_expiration_year}
                  </Link>
                ) : (
                  '—'
                )}
              </strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="body1">Type:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>{row.card_type}</strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="body1">Name:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>
                {row.first_name} {row.last_name}
              </strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="body1">Email:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>{row.email}</strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="body1">Status:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>{row.card_status}</strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="body1">KYC status:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>{row.kyc_status}</strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="body1">Balance:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>
                {row.balance_total
                  ? `${Number(row.balance_total).toFixed(2)} EUR`
                  : '—'}
              </strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="body1">Hold:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>
                {row.balance_hold
                  ? `${Number(row.balance_hold).toFixed(2)} EUR`
                  : '—'}
              </strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="body1">Promocode:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>
                <Promo promo={row.promocode} />
              </strong>
            </Typography>
          </Grid>
        </Grid>
        {row.card_status === 'active' && !transfer && tab === TabsEnum.Partner && (
          <Button
            style={{ margin: '16px 0' }}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => setTransfer(true)}
          >
            Top Up
          </Button>
        )}
        {transfer && (
          <div className={classes.cardTopUp}>
            <Typography variant="h4">Card Top Up</Typography>
            <form onSubmit={formik.handleSubmit} className={classes.form}>
              <TextField
                name="amount"
                onChange={formik.handleChange}
                value={formik.values.amount}
                variant="outlined"
                label="Amount"
                error={Boolean(formik.errors.amount)}
                helperText={formik.errors.amount}
              />
              <Typography className={classes.refillDescription} variant="body2">
                Will be debited:{' '}
                {refillAmountData
                  ? Number(refillAmountData.total_amount).toFixed(2)
                  : '0.00'}{' '}
                EUR (Including commission{' '}
                {refillAmountData
                  ? (
                      Number(refillAmountData.total_amount) -
                      Number(refillAmountData.refill_amount)
                    ).toFixed(2)
                  : '0.00'}{' '}
                EUR)
              </Typography>
              <div>
                <LoadingButton
                  loading={formik.isSubmitting}
                  style={{ margin: '16px 16px 16px 0' }}
                  size="large"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Confirm
                </LoadingButton>
                <Button
                  style={{ margin: '16px 0' }}
                  size="large"
                  variant="contained"
                  color="secondary"
                  onClick={() => setTransfer(false)}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  },
);
