import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CisFiatCurrencyPaymentMetadata,
  PromocodeTarget,
} from '@acme/partners-private-http-api-client/dist/types';
import { useFormik } from 'formik';
import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  makeStyles,
  Select,
  Typography,
} from '@material-ui/core';
import { useLocation } from 'wouter';

import { createPromocode, getCardPriceList } from '../../api';
import { LoadingButton } from '../LoadingButton';
import { usePartners } from '../../hooks/usePartners';
import { SnackbarType, useSnackbar } from '../../hooks/useSnackbar';
import { snakeCaseToSentence } from '../../libs/helpers';

const useStyles = makeStyles((theme) => ({
  textField: {
    display: 'block',
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      2,
    )}px 0`,
    width: '50ch',
  },
  checkbox: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
}));

export const CreatePromocode = () => {
  const classes = useStyles();
  const selectedPartnerId = usePartners((s) => s.selectedPartnerId);
  const setSnackbarValue = useSnackbar((s) => s.setSnackbarValue);
  const [, setLocation] = useLocation();

  const [cardPriceList, setCardPriceList] = useState<
    Array<CisFiatCurrencyPaymentMetadata>
  >([]);

  const fetchCardPriceList = useCallback(async () => {
    const response = await getCardPriceList();
    if (response.http_status_code === 200) {
      setCardPriceList(response.items);
    }
  }, []);

  useEffect(() => {
    void fetchCardPriceList();
  }, [fetchCardPriceList]);

  const formik = useFormik<{
    promocode_target: PromocodeTarget;
    agree: boolean;
  }>({
    initialValues: {
      promocode_target: 'plastic_card',
      agree: false,
    },
    async onSubmit(values) {
      if (selectedPartnerId) {
        const response = await createPromocode(
          selectedPartnerId,
          values.promocode_target,
        );
        if (response.http_status_code === 200) {
          setSnackbarValue(
            'Promocode successfully created',
            SnackbarType.Success,
          );
          setLocation('/promocodes');
        } else {
          setSnackbarValue(
            response.error_message || snakeCaseToSentence(response.error_code),
            SnackbarType.Error,
          );
        }
      }
    },
  });

  const selectedCardPrice = useMemo(
    () =>
      cardPriceList.find(
        (p) =>
          p.card_type.toLowerCase() ===
          formik.values.promocode_target.split('_')[0],
      ),
    [formik.values.promocode_target, cardPriceList],
  );

  return (
    <div>
      <Typography gutterBottom variant="h2">
        Create promocode
      </Typography>
      <Card>
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <FormControl className={classes.textField}>
              <InputLabel htmlFor="promocode_target">Card type</InputLabel>
              <Select
                native
                inputProps={{
                  id: 'promocode_target',
                  name: 'promocode_target',
                }}
                label="Card type"
                onChange={formik.handleChange}
                value={formik.values.promocode_target}
                fullWidth
              >
                <option value="plastic_card">Plastic card</option>
                <option value="virtual_card">Virtual card</option>
              </Select>
            </FormControl>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={formik.values.agree}
                  onChange={formik.handleChange}
                  name="agree"
                  color="primary"
                />
              }
              label={`I agree to debit ${
                selectedCardPrice?.fiat_currency_order_price || ''
              } ${
                selectedCardPrice?.fiat_currency_symbol || ''
              } for the issue of the card from my account`}
            />
            <LoadingButton
              disabled={!formik.values.agree}
              loading={formik.isSubmitting}
              size="large"
              variant="contained"
              color="primary"
              type="submit"
            >
              Create
            </LoadingButton>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};
