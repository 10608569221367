import { useCallback, useEffect, useRef, useState } from 'react';
import { CardPartnerUserData } from '@acme/partners-private-http-api-client/dist/types';
import { useUpdateEffect } from 'react-use';

import { IColumn, InfiniteTable } from '../InfiniteTable';
import { getPartnerCardUsers, getPendingCardUsers } from '../../api';
import { usePartners } from '../../hooks/usePartners';
import { snakeCaseToSentence } from '../../libs/helpers';

import { CardContent } from './CardContent';
import { TabsEnum } from './tabs.enum';

const columns: Array<IColumn<CardPartnerUserData>> = [
  {
    label: 'Last 4',
    format: (row) =>
      row.card_number_masked
        ? `*${row.card_number_masked.split('****')[1]}`
        : '—',
  },
  {
    label: 'Type',
    format: (row) => snakeCaseToSentence(row.card_type),
  },
  {
    label: 'Exp',
    format: (row) =>
      row.card_expiration_month && row.card_expiration_year
        ? `${row.card_expiration_month}/${row.card_expiration_year}`
        : '—',
  },
  {
    label: 'Balance',
    field: 'balance_total',
    align: 'right',
    format: (row) =>
      row.balance_total ? `${Number(row.balance_total).toFixed(2)} EUR` : '—',
  },
  {
    label: 'Email',
    field: 'email',
  },
  {
    label: 'Status',
    field: 'card_status',
  },
];

const LIMIT = 20;

export const CardsList = ({ tab }: { tab: TabsEnum }) => {
  const [users, setUsers] = useState<Array<CardPartnerUserData>>([]);
  const selectedPartnerId = usePartners((s) => s.selectedPartnerId);
  const [partnerId, setPartnerId] = useState(selectedPartnerId);

  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const skipRef = useRef(0);

  useUpdateEffect(() => {
    if (selectedPartnerId && tab) {
      setUsers([]);
      setHasMore(true);
      skipRef.current = 0;
      setPartnerId(selectedPartnerId);
    }
  }, [selectedPartnerId, tab]);

  const fetchUsers = useCallback(async () => {
    if (partnerId) {
      setLoading(true);
      let items: Array<CardPartnerUserData> = [];
      if (tab === TabsEnum.Partner) {
        const response = await getPartnerCardUsers(
          partnerId,
          skipRef.current,
          LIMIT,
        );
        if (response.http_status_code === 200) {
          items = response.items;
        }
      } else {
        const response = await getPendingCardUsers(
          partnerId,
          skipRef.current,
          LIMIT,
        );
        if (response.http_status_code === 200) {
          items = response.items;
        }
      }
      if (items.length > 0) {
        setUsers((s) => [...s, ...items]);
      } else {
        setHasMore(false);
      }
      setLoading(false);
    }
  }, [partnerId, tab]);

  useEffect(() => {
    void fetchUsers();
  }, [fetchUsers]);

  const onSkip = useCallback(() => {
    skipRef.current += LIMIT;
    void fetchUsers();
  }, [fetchUsers]);

  const getCollapseContent = useCallback(
    (row: CardPartnerUserData) => <CardContent row={row} tab={tab} />,
    [tab],
  );

  return (
    <InfiniteTable
      data={users}
      columns={columns}
      loading={loading}
      onSkip={onSkip}
      hasMore={hasMore}
      collapseContent={getCollapseContent}
    />
  );
};
