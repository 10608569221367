import PrivateClient from '@acme/partners-private-http-api-client';
// eslint-disable-next-line import/no-extraneous-dependencies
import ExternalHttpApiClientBase from '@acme/external-http-api-client-base';
import {
  CardPartnerLedgerAction,
  CardPartnerLedgerRecordType,
  PromocodeTarget,
} from '@acme/partners-private-http-api-client/dist/types';

import { config } from '../config';
import { SnackbarType, useSnackbar } from '../hooks/useSnackbar';

import { refreshAccess } from './public.api';

export const apiClient = new PrivateClient(config.apiUrl);

export function setAuthorizationToken() {
  const token = localStorage.getItem('access_token');
  if (token) {
    apiClient.setAuthorizationToken(token);
  }
}

export function clearAuthorizationToken() {
  apiClient.clearAuthorizationToken();
}

setAuthorizationToken();

type TApiClient = Omit<typeof apiClient, keyof ExternalHttpApiClientBase>;

type Unwrap<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: Array<unknown>) => Promise<infer U>
  ? U
  : T extends (...args: Array<unknown>) => infer U
  ? U
  : T;

export async function request<T extends keyof TApiClient>(
  method: T,
  ...args: Parameters<TApiClient[T]>
): Promise<Unwrap<ReturnType<TApiClient[T]>>> {
  // @ts-expect-error
  const r = await apiClient[method](...args);

  if (r.http_status_code !== 200) {
    if (r.error_code === 'EXPIRED' || r.error_code === 'UNAUTHORIZED') {
      const ok = await refreshAccess();
      if (ok) {
        setAuthorizationToken();
        return request(method, ...args);
      }
    }

    if (r.error_code === 'USER_NOT_FOUND') {
      useSnackbar.setState({
        snackbarValue: 'User is not allowed to the service',
        snackbarType: SnackbarType.Error,
      });
      clearAuthorizationToken();
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    }
  }

  return (r as unknown) as Promise<Unwrap<ReturnType<TApiClient[T]>>>;
}

export async function getPartners(skip: number, limit: number) {
  return request('cardPartnersPartnersByUserIdentityList', {
    skip,
    limit,
  });
}

export async function getPartner(id: string) {
  return request('cardPartnersGetPartner', { card_partner_id: id });
}

interface IGetLedgerRecordsBody {
  card_partner_id: string;
  card_id?: string | null;
  skip: number;
  limit: number;
  from_date?: string;
  to_date?: string;
  record_action?: CardPartnerLedgerAction;
  record_type?: CardPartnerLedgerRecordType;
}

export async function getLedgerRecords(data: IGetLedgerRecordsBody) {
  return request('cardPartnersPartnerLedgerRecordsList', data);
}

export async function getPromocodes(
  cardPartnerId: string,
  skip: number,
  limit: number,
) {
  return request('cardPartnersPartnerPromocodesList', {
    card_partner_id: cardPartnerId,
    skip,
    limit,
  });
}

export async function getPartnerCardUsers(
  cardPartnerId: string,
  skip: number,
  limit: number,
) {
  return request('cardPartnersGetPartnerCardUsers', {
    card_partner_id: cardPartnerId,
    skip,
    limit,
  });
}

export async function getPendingCardUsers(
  cardPartnerId: string,
  skip: number,
  limit: number,
) {
  return request('cardPartnersPendingCardsList', {
    card_partner_id: cardPartnerId,
    skip,
    limit,
  });
}

export async function refillUserCard(data: {
  card_partner_id: string;
  target_user_identity_id: string;
  amount: string;
  card_id: string;
}) {
  return request('cardPartnersRefillUserCard', data);
}

export async function createPromocode(
  cardPartnerId: string,
  promocodeTarget: PromocodeTarget,
) {
  return request('cardPartnersCreatePromocode', {
    card_partner_id: cardPartnerId,
    promocode_target: promocodeTarget,
  });
}

export async function getCardPriceList() {
  return request('cardPartnersCardsPriceList', {});
}

export async function calculateCardRefillAmount(
  cardPartnerId: string,
  amount: string,
) {
  return request('cardPartnersCalculateCardRefillAmount', {
    card_partner_id: cardPartnerId,
    card_refill_amount: amount,
  });
}
