import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: '#5F46F9',
        light: '#858AFF',
      },
      text: {
        primary: '#121A25',
        secondary: '#89919D',
      },
    },
    typography: {
      fontFamily: [
        'Montserrat',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      h1: {
        fontSize: 40,
        fontWeight: 700,
      },
      h2: {
        fontSize: 28,
        fontWeight: 600,
      },
    },
    overrides: {
      MuiCardContent: {
        root: {
          padding: '32px 40px',
        },
      },
      MuiButton: {
        sizeLarge: {
          minWidth: 150,
          height: 50,
        },
        root: {
          textTransform: 'initial',
        },
      },
    },
  }),
);

if (theme.overrides?.MuiCardContent) {
  theme.overrides.MuiCardContent.root = {
    padding: '32px 40px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  };
}

export default theme;
