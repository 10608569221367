import { useCallback, useEffect, useRef, useState } from 'react';
import { Promocode } from '@acme/partners-private-http-api-client/dist/types';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { Button } from '@material-ui/core';
import { Link } from 'wouter';

import { IColumn, InfiniteTable } from '../InfiniteTable';
import { getPromocodes } from '../../api';
import { Promo } from '../Promo';
import { usePartners } from '../../hooks/usePartners';
import { snakeCaseToSentence } from '../../libs/helpers';

const columns: Array<IColumn<Promocode>> = [
  {
    field: 'created_at',
    label: 'Created At',
    format: (row) => (
      <>
        {new Date(row.created_at).toLocaleString()}
        <br />
        {formatDistanceToNow(new Date(row.created_at), { addSuffix: true })}
      </>
    ),
    minWidth: 180,
  },
  {
    field: 'code',
    label: 'Code',
    format: (row) => <Promo promo={row.code} />,
    minWidth: 260,
  },
  {
    field: 'status',
    label: 'Status',
  },
  {
    field: 'promocode_target',
    label: 'Target',
    format: (row) => snakeCaseToSentence(row.promocode_target),
  },
  {
    field: 'redeemed_at',
    label: 'Redeemed At',
    format: (row) =>
      row.redeemed_at ? (
        <>
          {new Date(row.redeemed_at).toLocaleString()}
          <br />
          {formatDistanceToNow(new Date(row.redeemed_at), { addSuffix: true })}
        </>
      ) : (
        '—'
      ),
    minWidth: 180,
  },
];

const LIMIT = 20;

export const Promocodes = () => {
  const [promocodes, setPromocodes] = useState<Array<Promocode>>([]);
  const selectedPartnerId = usePartners((s) => s.selectedPartnerId);
  const [partnerId, setPartnerId] = useState(selectedPartnerId);

  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const skipRef = useRef(0);

  useEffect(() => {
    if (selectedPartnerId) {
      setPromocodes([]);
      setHasMore(true);
      skipRef.current = 0;
      setPartnerId(selectedPartnerId);
    }
  }, [selectedPartnerId]);

  const fetchPromocodes = useCallback(async () => {
    if (partnerId) {
      setLoading(true);
      const response = await getPromocodes(partnerId, skipRef.current, LIMIT);
      if (response.http_status_code === 200) {
        if (response.items.length > 0) {
          setPromocodes((s) => [...s, ...response.items]);
        } else {
          setHasMore(false);
        }
      }
      setLoading(false);
    }
  }, [partnerId]);

  const onSkip = useCallback(() => {
    skipRef.current += LIMIT;
    void fetchPromocodes();
  }, [fetchPromocodes]);

  useEffect(() => {
    void fetchPromocodes();
  }, [fetchPromocodes]);

  return (
    <>
      <div style={{ marginBottom: 24 }}>
        <Link href="/promocodes/create">
          <Button
            size="large"
            variant="contained"
            color="primary"
            type="button"
          >
            Create promocode
          </Button>
        </Link>
      </div>
      <InfiniteTable
        data={promocodes}
        columns={columns}
        loading={loading}
        onSkip={onSkip}
        hasMore={hasMore}
      />
    </>
  );
};
