import create from 'zustand';
import { CardPartnerBaseView } from '@acme/partners-private-http-api-client/dist/types';

import { getPartners } from '../api';

type TUsePartners = {
  selectedPartnerId?: string | null;
  partners: Array<CardPartnerBaseView>;
  fetchPartners: () => Promise<void>;
  setSelectedPartnerId: (id: string) => void;
};

export const usePartners = create<TUsePartners>((set) => ({
  partners: [],
  async fetchPartners() {
    const response = await getPartners(0, 10);
    if (response.http_status_code === 200) {
      set({
        partners: response.items,
        selectedPartnerId:
          localStorage.getItem('card_partner_id') ||
          response.items[0]?.card_partner_id,
      });
    }
  },
  setSelectedPartnerId(id: string) {
    localStorage.setItem('card_partner_id', id);
    set({ selectedPartnerId: id });
  },
  selectedPartnerId: localStorage.getItem('card_partner_id'),
}));
