import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import cx from 'clsx';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 'calc(100vh - 182px)',
  },
  table: {
    borderCollapse: 'collapse',
  },
  rootRow: {
    '& > *': {
      borderBottom: 'unset',
    },
    marginBottom: theme.spacing(1),
  },
  rowOpened: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  rowOnClick: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  progress: {
    position: 'absolute',
    marginLeft: -1,
    marginTop: -1,
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
}));

export interface IColumn<T> {
  field?: keyof T;
  label: string;
  minWidth?: number;
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
  format?: (row: T) => React.ReactElement | string;
}

interface IProps<T> {
  data: Array<T>;
  columns: Array<IColumn<T>>;
  onTableRowClick?: (evt: React.MouseEvent, row: T) => void;
  loading?: boolean;
  hasMore?: boolean;
  onSkip?: () => void;
  rowKey?: keyof T;
  errorMessage?: string;
  rowStyle?: (row: T) => React.CSSProperties | undefined;
  collapseContent?: (row: T) => React.ReactElement | string;
}

const Row = <T,>({
  row,
  rowStyle,
  onTableRowClick,
  rowRef,
  columns,
  collapseContent,
}: {
  row: IProps<T>['data'][number];
  rowStyle: IProps<T>['rowStyle'];
  onTableRowClick: IProps<T>['onTableRowClick'];
  columns: IProps<T>['columns'];
  collapseContent: IProps<T>['collapseContent'];
  rowRef: ((node: Element | null) => void) | null;
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        hover
        className={cx({
          [classes.rowOpened]: open,
          [classes.rowOnClick]: Boolean(onTableRowClick),
        })}
        style={rowStyle && rowStyle(row)}
        onClick={
          onTableRowClick
            ? (evt) => {
                onTableRowClick(evt, row);
              }
            : undefined
        }
        ref={rowRef}
      >
        {collapseContent && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen((s) => !s)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {columns.map((column) => (
          <TableCell key={column.label} align={column.align}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {column.format
              ? column.format(row)
              : column.field
              ? row[column.field]
              : null}
          </TableCell>
        ))}
      </TableRow>
      {collapseContent && (
        <TableRow
          className={cx(classes.rootRow, {
            [classes.rowOpened]: open,
          })}
        >
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={columns.length + 1}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>{collapseContent(row)}</Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const typedMemo: <T>(c: T) => T = React.memo;

export const InfiniteTable = typedMemo(
  // eslint-disable-next-line @typescript-eslint/ban-types
  <T extends object>({
    data,
    columns,
    onTableRowClick,
    loading,
    hasMore,
    onSkip,
    rowKey,
    errorMessage,
    rowStyle,
    collapseContent,
  }: IProps<T>) => {
    const classes = useStyles();

    const tableContainerRef = useRef<HTMLDivElement>(null);
    const [progressWidth, setProgressWidth] = useState<number>();

    const observer = useRef<IntersectionObserver>();
    const lastElementRef = useCallback(
      (node: Element | null) => {
        if (loading) {
          return;
        }
        if (observer.current) {
          observer.current.disconnect();
        }
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && hasMore) {
            if (onSkip) {
              onSkip();
            }
          }
        });
        if (node) {
          observer.current.observe(node);
        }
      },
      [loading, hasMore, onSkip],
    );

    useEffect(() => {
      if (data) {
        setProgressWidth(tableContainerRef.current?.clientWidth);
      }
    }, [data]);

    return (
      <TableContainer
        component={Paper}
        className={classes.container}
        ref={tableContainerRef}
      >
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {collapseContent && <TableCell />}
              {columns.map((column) => (
                <TableCell
                  key={column.label}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
            <tr>
              <td>
                {loading && (
                  <LinearProgress
                    style={{
                      width: progressWidth,
                    }}
                    className={classes.progress}
                  />
                )}
              </td>
            </tr>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <Row
                key={`row-${rowKey ? row[rowKey] : index}`}
                rowRef={data.length === index + 1 ? lastElementRef : null}
                row={row}
                columns={columns}
                onTableRowClick={onTableRowClick}
                rowStyle={rowStyle}
                collapseContent={collapseContent}
              />
            ))}
          </TableBody>
        </Table>
        {!loading && !errorMessage && data.length === 0 && (
          <div className={classes.noData}>
            <Typography variant="body1">No data</Typography>
          </div>
        )}
        {errorMessage && (
          <div className={classes.noData}>
            <Typography color="error" variant="body1">
              {errorMessage}
            </Typography>
          </div>
        )}
      </TableContainer>
    );
  },
);
