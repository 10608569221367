export class LocalCredentialsStorage {
  private readonly name: string;

  public constructor(name: string) {
    this.name = name;
  }

  public async getItem(): Promise<string | undefined> {
    return localStorage.getItem(this.name) || undefined;
  }

  public async setItem(value: string): Promise<void> {
    localStorage.setItem(this.name, value);
  }

  public async removeItem(): Promise<void> {
    localStorage.removeItem(this.name);
  }
}
