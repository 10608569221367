import { FormEvent, useCallback, useState } from 'react';
import { Button, TextField, Typography, makeStyles } from '@material-ui/core';
import { useLocation } from 'wouter';

import {
  identityLogin,
  identitySecretConfirm,
} from '../../api/identityLoginSession';
import { usePartners } from '../../hooks/usePartners';
import { useSnackbar, SnackbarType } from '../../hooks/useSnackbar';
import { LoadingButton } from '../LoadingButton';

export const styles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingTop: 100,
  },
  textField: {
    width: '100%',
  },
  form: {
    maxWidth: 400,
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1rem',
  },
  signIn: {
    width: '130px',
  },
});

type TStep = 'login' | 'secret';

export const Login = () => {
  const classes = styles();
  const [step, setStep] = useState<TStep>('login');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [secret, setSecret] = useState<string>('');
  const [authToken, setAuthToken] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const fetchPartners = usePartners((s) => s.fetchPartners);
  const setSnackbarValue = useSnackbar((s) => s.setSnackbarValue);
  const [, setLocation] = useLocation();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    if (step === 'login') {
      try {
        const authResponse = await identityLogin({
          email,
          password,
        });

        if (authResponse.authToken) {
          setAuthToken(authResponse.authToken);
          setLoading(false);
          setStep('secret');
        }
      } catch (error: unknown) {
        setLoading(false);
        setSnackbarValue((error as Error).message, SnackbarType.Error);
      }
    }
    if (step === 'secret') {
      setLoading(true);
      try {
        const secretConfirm = await identitySecretConfirm({
          authToken,
          secret,
        });

        if (secretConfirm.accessToken) {
          localStorage.setItem('access_token', secretConfirm.accessToken);
          localStorage.setItem('refresh_token', secretConfirm.refreshToken);
          localStorage.setItem('email', email);

          await fetchPartners();

          setLocation('/cards');
          setLoading(false);
        }
      } catch (error: unknown) {
        setLoading(false);
        setSnackbarValue((error as Error).message, 1);
      }
    }
  };
  const resetForm = useCallback(() => {
    setEmail('');
    setPassword('');
    setSecret('');
    setAuthToken('');
    setStep('login');
  }, []);

  return (
    <div className={classes.wrapper}>
      <form className={classes.form} onSubmit={onSubmit}>
        <Typography variant="h4" gutterBottom>
          Welcome
        </Typography>

        {step === 'login' && (
          <>
            <TextField
              required
              label="Email"
              className={classes.textField}
              value={email}
              name="login"
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              variant="outlined"
            />
            <TextField
              required
              label="Password"
              className={classes.textField}
              value={password}
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              variant="outlined"
              type="password"
            />
          </>
        )}

        {step === 'secret' && (
          <TextField
            required
            label="Code"
            className={classes.textField}
            value={secret}
            name="secret"
            onChange={(e) => setSecret(e.target.value)}
            margin="normal"
            variant="outlined"
          />
        )}

        <div className={classes.actions}>
          <LoadingButton
            loading={loading}
            className={classes.signIn}
            variant="contained"
            color="primary"
            type="submit"
          >
            {step === 'login' ? 'log in' : 'Join'}
          </LoadingButton>
          {step !== 'login' && (
            <Button
              className={classes.signIn}
              variant="outlined"
              type="button"
              onClick={resetForm}
            >
              Cancel
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};
